const __request = require(`../__request/__request_contentType_json`);


export default (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/buyer/dom/manage/confirmEnquiry',
    data: data
  }

  return __request(pParameter)
}


