// 获取详情
import buyer_dom_manage_enquiryDetail from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_enquiryDetail";
// 确认报价并支付
import buyer_dom_manage_confirmEnquiry from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_confirmEnquiry";


export default {
    data() {
        return {
            // 询价单号
            enqOrderNo: '',
            detailData: {},
            loading: true,
            defaultText: "------",
            type: '',   // detail--详情、operate--操作
        }
    },
    components: {},
    created() {
    },
    mounted() {

    },
    activated() {
      this.type = this.$route.query.type ? this.$route.query.type : 'detail';
      this.getDetail();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        // 确认报价并支付
        quotedPriceClick(){
          this.loading = true;
          let dataBox = {
            enqOrderNo: this.enqOrderNo,
          };
          buyer_dom_manage_confirmEnquiry(dataBox).then(res => {
            this.loading = false;
            console.log(res);
            this.$router.push({
              name: `air-pay`,
              query: {
                ordersNo: res.orderNo
              },
            });
          }).catch(res => {
              console.log('res --->>>', res)
          })
          ;
        },
        getDetail(){
          this.enqOrderNo = this.$route.query.enqOrderNo;
          if (this.enqOrderNo) {
            let dataBox = {
              enqOrderNo: this.enqOrderNo,
            };
            buyer_dom_manage_enquiryDetail(dataBox).then(res => {
              this.loading = false;
              this.detailData = res.enquiryDetail;
            });
          } else {
            this.loading = false;
          }
        },
        back() {
            this.$router.go(-1);
        },
        toPay(){
            this.$router.push({
                name: `air-pay`,
                query: {
                    ordersNo: this.detailData.orderNo
                },
            });

        },
    }
}
